<template>
  <div class="page">
        <!-- <div class="search_wap" style="text-align:center">
            
        </div> -->
        <div class="" style="text-align:left;padding-left:20px">
            <a-button  class="addBtn fixedBtn" size="large" type="primary" @click="showAdd">添加技能</a-button>
        </div>
       <div class="lists" v-for="item in data" :key="item">
            <div class="list_row">
                <div class="flex">
                    <!-- <div>{{item.id}}</div> -->
                    <!-- <div><label for="">编号：</label>{{item.id}}</div> -->
                    <div><label for="">分类名：</label>{{item.name}}</div>
                    <div><label for="">日薪：</label>{{item.salary}}</div>
                </div>
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="editType(item)">编辑</a-button>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
            </div>
        </div>
         <a-modal :afterClose="hideShow" v-model="visible" title="添加技能"  @ok="addType">
            <div class="addBox">
                <div class="add_title">请输入技能名称</div>
                <a-input class="add_inp" style="margin-bottom:15px" v-model="addName" />
                <div class="add_title">请输入技能日薪资</div>
                <a-input class="add_inp" v-model="addSalary" />
            </div>
        </a-modal>
    </div>
    
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../../../assets/js/request';
export default {
    data() {
        return {
            data:'',
            visible:false,
            onID:'', //编辑状态id
            addName:'', //添加分类名称
            addSalary:'',//添加薪资
        };
    },
    mounted: function(){
        this.getData();
    },
    methods: {
       getData() {
        //    let that=this;
            requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                // console.log(res.list);
                this.data=res.list; 
                // console.log( this.data);
            },{"page":{"curpage":1,"pagesize":999}})
        }, 
        //添加分类
        showAdd(){
            this.visible = true;
        },
        hideShow(){
            this.visible = false;
            this.onID='';
            this.addName='';
            this.addSalary='';
        },
        addType(){
            // console.log(this.addText);
            let that=this;
            if(this.addName!=""){
            let url="/jieb/Temporary/skilladd";
            let data={'name':this.addName,'salary':this.addSalary}
            if(this.onID){
                url="/jieb/Temporary/skilledit";
                data.id=this.onID;
            }
                requestXml(url,"POST",() => {
                    // console.log(res)

                    that.getData();
                    this.visible=false;
                    this.onID='';
                    this.addName='';
                    this.addSalary='';
                    
                },data)
            }
         
        },
        // 编辑
        editType(res){
            this.onID=res.id;
            this.addName=res.name;
            this.addSalary=res.salary;
            this.visible=true;
        },
        // 删除
        onDelete(id){
            requestXml("/jieb/Temporary/skilldel","POST",(res) => {
                console.log(res)
                // this.data=[];
                this.getData();
            },{"id": id})
        }
    },
    
};
</script>
<style scoped>
    .page{margin-top: -15px;padding-top: 15px;min-height: calc(100vh + 15px);}
    .search{display: flex;justify-content: space-between;}
    .addBtn{width: auto;}
     .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    }
    .action{display:inline-block;padding:5px 10px;margin:0 5px;background:#dceeff;border:1px solid #36a3ff;color:#36a3ff;border-radius:5px}
    .del{background:#ffdcdc;border-color:#ff4c4c;color:#ff4c4c}
    .add_title{padding: 15px 0;}
    .add_inp{height: 40px;margin-bottom: 60px;}
     .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
    }
    

    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
</style>

